'use client'

import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { Toaster } from '@/components/ui/toaster'

import { PHProvider } from './PHProvider'
import ReactQueryProvider from './ReactQueryProvider'

const PHPageView = dynamic(() => import('./PHPageView'), {
  ssr: false,
})

export function Providers({ children }: { children: ReactNode }) {
  return (
    <>
      <PHProvider>
        <PHPageView />
        <ReactQueryProvider>{children}</ReactQueryProvider>
      </PHProvider>

      <ToastContainer
        className="px-2 pointer-events-auto"
        hideProgressBar={true}
        position="top-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        autoClose={45000}
        closeButton={<div className="mt-2 mr-1 text-dark-gray-500">x</div>}
      />
      <Toaster />
    </>
  )
}
